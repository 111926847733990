<template>
  <div class="sourceForm">
    <div class="bottom-wrap">
      <search-data
        ref="search"
        :title="'模型名称：'"
        :placeholder="'关键词'"
        @goNextEvent="goNext"
        :searchUrl="getModelList"
        :params="params"
        :showAry="showAry"
        :showObj="showObj"
        :showTitle="showTitle"
        @goNextAdd="add"
        @del="delectSource"
        :isShowOpear="true"
        :isShowIcon="false"
        @goViewInfo="goViewInfo"
        tipContent="查看模型定义"
      ></search-data>
      <div>
        <v-dialog
          :title="title"
          v-model="outerVisible"
          width="50%"
          @cancel="dataInit"
          @close="dataInit"
          @confirm="submit"
          sureTxt="完成"
        >
          <form-panel
            ref="formPanel"
            :form="form"
            :label-position="'left'"
            :hasHeader="false"
            :labelWidth="'180px'"
            class="item-form"
          >
            <div class="title">基本信息</div>
            <el-form-item
              label="模型名称"
              :rules="[
                { required: true, message: '请输入名称', trigger: 'blur' },
              ]"
              prop="name"
            >
              <v-input
                placeholder="请输入模型名称"
                v-model="form.name"
              ></v-input>
            </el-form-item>

            <el-form-item
              label="模型数据更新周期"
              :rules="[
                { required: true, message: '请选择更新周期', trigger: 'blur' },
              ]"
              prop="type"
            >
              <div class="item-model">
                <v-checkbox
                  v-model="item.checked"
                  v-for="(item, index) in timeOptions"
                  :key="index"
                  :index="index"
                  type="border"
                  :label="item.label"
                  @change="checkTime(item)"
                />
                <div>
                  <v-checkbox
                    v-model="item.checked"
                    v-for="(item, index) in cronOptions"
                    :key="index"
                    :index="index"
                    type="border"
                    :label="item.label"
                    @change="checkCron(item)"
                  />
                </div>

                <div v-if="cronOptions[0].checked === true">
                  <el-form-item
                    :rules="[
                      {
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur',
                      },
                    ]"
                    prop="cron"
                  >
                    <v-input
                      placeholder="请输入表达式"
                      v-model="form.cron"
                    ></v-input>
                  </el-form-item>
                </div>
              </div>
            </el-form-item>
            <div class="title">数据源输入</div>
            <!-- 数据源输入 -->
            <el-form-item
              label="选择数据源"
              :rules="[
                {
                  required: true,
                  message: '请选择数据源',
                  trigger: 'blur',
                },
              ]"
              prop="datasourceId"
            >
              <el-select
                v-model="form.datasourceId"
                ref="modelSelect"
                filterable
                :disabled="sourceDis"
                remote
                :clearable="true"
                reserve-keyword
                placeholder="请选择数据源"
                :remote-method="remoteMethod"
                @clear="clearChance"
                @visible-change="visibleChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                  @click.native="getChange"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <div class="title">数据转换</div>
            <!-- 数据过滤 -->
            <el-form-item label="数据过滤" prop="dataFilt">
              <div class="check-panel">
                <v-checkbox
                  :disabled="filtDis"
                  v-model="form.dataFilt"
                  v-for="(item, index) in fitOptions"
                  :key="index"
                  :index="index"
                  type="border"
                  :label="item.label"
                  @change="goFiltration()"
                />
              </div>
            </el-form-item>
            <!-- 数据计算 -->
            <el-form-item label="数据计算">
              <div class="check-panel">
                <v-checkbox
                  :disabled="disableCalc"
                  v-model="form.dataComputed"
                  v-for="(item, index) in comOptions"
                  :key="index"
                  :index="index"
                  type="border"
                  :label="item.label"
                  @change="goComputed()"
                />
              </div>
            </el-form-item>
            <div class="title">数据输出</div>
            <!-- 存储对象 -->
            <el-form-item
              label="选择存储对象"
              :rules="[
                {
                  required: true,
                  message: '请选择存储对象',
                  trigger: 'blur',
                },
              ]"
              prop="datastoreId"
            >
              <el-select
                v-model="form.datastoreId"
                filterable
                :disabled="storeDis"
                remote
                :clearable="true"
                reserve-keyword
                placeholder="请选择存储对象"
                :remote-method="remoteStore"
                @change="getStore"
              >
                <el-option
                  v-for="item in storeOpt"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </form-panel>
          <!-- 数据过滤弹窗 -->
          <v-dialog
            title="数据过滤"
            v-model="dialogFit"
            width="50%"
            @close="closeFilter"
            @cancel="closeFit"
            @confirm="submitFit"
            sureTxt="完成"
            append-to-body
          >
            <form-panel :form="filtForm" ref="filtForm" :hasHeader="false">
              <el-form-item
                label="算子名称"
                :rules="[
                  { required: true, message: '请输入名称', trigger: 'blur' },
                ]"
                prop="name"
              >
                <v-input
                  placeholder="请输入名称"
                  v-model="filtForm.name"
                ></v-input
              ></el-form-item>

              <el-form-item label="过滤条件">
                <el-table height="250" :data="filtTale">
                  <el-table-column prop="field" label="字段" width="200">
                    <template slot-scope="scope">
                      <v-input v-model="scope.row.field" type="text" />
                    </template>
                  </el-table-column>
                  <el-table-column prop="operator" label="计算符号" width="200"
                    ><template slot-scope="scope">
                      <v-select
                        :width="116"
                        :placeholder="'请选择计算符号'"
                        :options="filtCode"
                        v-model="scope.row.operator"
                      /> </template
                  ></el-table-column>
                  <el-table-column prop="value" width="200" label="数值">
                    <template slot-scope="scope">
                      <v-input v-model="scope.row.value" type="text" />
                    </template>
                  </el-table-column>
                  <el-table-column label="表达试" prop="expression">
                    <template
                      slot-scope="scope"
                      v-if="scope.row.operator && scope.row.value"
                    >
                      {{
                        (scope.row.expression = `${scope.row.field}${scope.row.operator}${scope.row.value}`)
                      }}
                    </template>
                  </el-table-column>
                </el-table>
              </el-form-item>
              <el-form-item label="删除字段">
                <!-- <v-select
                  multiple
                  v-model="filtForm.delFields"
                  clearable
                  :options="deleteList"
                /> -->
                <el-select
                  style="margin-right: 20px; width: 100%"
                  v-model="filtForm.delFields"
                  clearable
                  multiple
                >
                  <el-option
                    v-for="item in deleteList"
                    :key="item.field"
                    :label="item.field"
                    :value="item.field"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </form-panel>
          </v-dialog>
          <!-- 数据计算弹窗 -->
          <v-dialog
            title="数据计算"
            v-model="dialogComputed"
            width="50%"
            append-to-body
            @close="closeCalcDialog"
            sureTxt="完成"
            @cancel="cancelbtn"
            @confirm="submitCalc"
          >
            <form-panel
              :form="formCalcData"
              ref="formCalcData"
              :hasHeader="false"
            >
              <el-form-item
                label="算子名称"
                :rules="[
                  { required: true, message: '请输入名称', trigger: 'blur' },
                ]"
                prop="name"
              >
                <v-input
                  placeholder="请输入数据源名称"
                  v-model="formCalcData.name"
                ></v-input
              ></el-form-item>
              <div v-for="(item, index) in aggregationConfigs" :key="index">
                <el-form-item
                  label="算式规则"
                  :rules="[
                    {
                      required: true,
                      message: '请输入名称',
                      trigger: 'change',
                    },
                  ]"
                >
                  <el-select
                    style="margin-right: 20px"
                    v-model="item.field"
                    clearable
                  >
                    <el-option
                      v-for="item in fieldList"
                      :key="item.field"
                      :label="item.field"
                      :value="item.field"
                    >
                    </el-option>
                  </el-select>
                  <v-select
                    :width="100"
                    style="margin-right: 20px"
                    v-model="item.operators"
                    clearable
                    @change="handlerInputChange(item)"
                    :options="symbolCode"
                  />
                  <v-input
                    :width="150"
                    placeholder="请输入具体数字"
                    v-model="item.value"
                    @change="handlerInputChange(item)"
                  ></v-input>
                  <span style="margin: 0 20px">或</span>
                  <v-input
                    :width="150"
                    placeholder="请输入具体数字"
                    v-model="item.expression"
                  ></v-input>
                </el-form-item>
                <el-form-item
                  label="是否保留"
                  :rules="[
                    { required: true, message: '请输入名称', trigger: 'blur' },
                  ]"
                >
                  <v-select
                    v-model="item.reserveType"
                    clearable
                    @change="handlerChange(item)"
                    :options="isSaveOption"
                  />
                </el-form-item>
                <el-form-item v-if="item.reserveType == 1">
                  <el-table height="200" :data="item.dataConfig">
                    <el-table-column prop="field" label="字段名">
                      <template slot-scope="scope">
                        <v-input v-model="scope.row.field" type="text" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注">
                      <template slot-scope="scope">
                        <v-input v-model="scope.row.remark" type="text" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="type" label="类型">
                      <template slot-scope="scope">
                        <v-input v-model="scope.row.type" type="text" />
                      </template>
                    </el-table-column>
                    <el-table-column label="长度">
                      <template slot-scope="scope">
                        <v-input v-model="scope.row.size" type="text" />
                      </template>
                    </el-table-column>
                    <el-table-column label="小数点">
                      <template slot-scope="scope">
                        <v-input v-model="scope.row.decimals" type="text" />
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form-item>
              </div>
              <v-button
                @click="addRule"
                :text="'新增'"
                style="margin-left: 50px"
              >
              </v-button>
            </form-panel>
          </v-dialog>
        </v-dialog>
      </div>
    </div>
    <!-- 查看弹窗 -->
    <v-dialog
      title="模型定义"
      class="viewDialog"
      v-model="isdialog"
      @confirm="isdialog = false"
      :showCancelButton="false"
      sureTxt="关闭"
      oStyle="justify-content: flex-end"
    >
      <v-table
        class="item-table"
        :tableData="formatFieldConfigs"
        ref="vTable"
        :headers="fieldConfigsHeaders"
        :isOperateColumn="false"
      >
      </v-table>
    </v-dialog>
    <!-- 定义模型属性弹窗 -->
    <v-dialog
      width="50%"
      title="属性定义"
      class="model-prop"
      v-model="isModelProp"
      @confirm="confirm"
    >
      <!-- 表格数据 -->
      <div class="item-c">
        <v-table
          class="item-table"
          :tableData="modelList"
          ref="modelTable"
          :headers="headers"
          :operateColumnLabel="'检验规则'"
        >
          <template #operateTeSlot="scope">
            <div class="opera-btn">
              <v-select
                :placeholder="'请输入检验规则'"
                :options="inspectionRuleOpt"
                :width="116"
                v-model="modelList[scope.row.$index].rule"
              />
            </div>
          </template>
        </v-table>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import searchData from "../components/search/index.vue";
import _ from "lodash";
import {
  getModelList,
  getSourceList,
  sourceDetailUrl,
  getCode,
  getSymbol,
  addModel,
  getStoreList,
  getStoreDetail,
  removeMode,
  watchInfo,
  getModelDetailUrl,
  getInSpectionRuleOpt,
} from "./api.js";
export default {
  components: {
    searchData,
  },
  data() {
    return {
      // 控制定义模型属性弹窗
      isModelProp: false,
      //属性定义数据
      modelList: [],
      // 表头数据
      headers: [
        {
          prop: "field",
          label: "字段",
        },
        {
          prop: "type",
          label: "类型",
        },
        {
          prop: "size",
          label: "长度",
        },
        {
          prop: "remark",
          label: "描述",
        },
      ],
      fieldConfigsHeaders: [
        {
          prop: "field",
          label: "字段",
        },
        {
          prop: "type",
          label: "类型",
        },
        {
          prop: "size",
          label: "长度",
        },
        {
          prop: "remark",
          label: "描述",
        },
        {
          prop: "rule",
          label: "检验规则",
        },
      ],
      // 检验规则下拉数据
      inspectionRuleOpt: [],
      pages: {
        total: 0,
        curPage: 1,
        pageSize: 50,
        type: 31,
      },
      //  控制查看弹窗显示隐藏
      isdialog: false,
      //提交表单数据
      form: {
        id: "", // 数据唯一值
        name: "",
        type: "",
        datasourceId: "", //数据源输入
        cron: "", // 表达试
        datastoreId: "", //存储对象
        dataFilt: false, // 是否选中数据过滤
        dataComputed: false, // 是否选中数据计算
      },
      //数据过滤表单数据
      filtForm: {
        delFields: [],
        name: "",
      },
      // 数据计算算子名称
      formCalcData: {
        name: "",
      },
      // 数据计算数据
      aggregationConfigs: [
        {
          field: "",
          value: "",
          operator: "",
          dataConfig: [],
          expression: "",
          reserveType: "", //  是否保留
        },
      ],
      isSaveOption: [
        {
          label: "保留原字段",
          value: 0,
        },
        {
          label: "创建新字段",
          value: 1,
        },
      ],

      options: [], //数据源下拉数据
      storeOpt: [], //存储对象下拉数据
      sourceDis: false,
      storeDis: false,
      title: "新建数据模型",
      filtCode: [], // 符号数据
      symbolCode: [], // 计算符号下拉数据
      filtDis: false,
      disableCalc: false,
      getModelList,
      showObj: "cardInfo",
      showAry: ["cron", "relatedDataSourceName", "relatedOperators"],
      showTitle: {
        cron: "更新周期",
        relatedDataSourceName: "关联数据源名称",
        relatedOperators: "关联算子",
      },
      outerVisible: false,
      params: {
        curPage: 1,
        pageSize: 12,
        name: undefined,
        type: undefined,
      },

      dialogFit: false,
      dialogComputed: false,
      cronOptions: [
        {
          value: "1",
          label: "固定周期（cron表达式）",
          checked: false,
        },
      ],
      timeOptions: [
        {
          value: "0",
          label: "实时",
          checked: false,
        },
      ],
      fitOptions: [
        {
          value: "数据过滤",
          label: "数据过滤",
          checked: false,
        },
      ],
      comOptions: [
        {
          value: "数据计算",
          label: "数据计算",
          checked: false,
        },
      ],

      requestList: [],
      filtTale: [
        {
          field: "",
          value: "",
          operator: "",
          expression: "",
        },
      ],
      deleteList: [
        {
          value: "problemName",
          label: "字段",
        },
        {
          value: "problemType",
          label: "计算符号",
        },
      ],
      fieldList: [],
      isCheckFilter: false,
      isCheckAggregation: false,
      isFiled: null,
      fieldConfigs: [],
    };
  },
  created() {
    this.remoteMethod();
    this.remoteStore();
  },
  mounted() {
    this.$refs.search.getList(this.params);
  },
  computed: {
    // 处理FieldConfigs 字段数据
    formatFieldConfigs() {
      this.fieldConfigs.forEach((item) => {
        if (!item.rule) {
          item.rule = "";
        } else {
          item.rule = item.rule.title;
        }
      });
      return this.fieldConfigs;
    },
  },
  watch: {
    filtTale: {
      deep: true,
      handler(value) {
        this.deleteList = value.filter((item) => !item.expression);
      },
    },
  },
  methods: {
    // 关闭查看弹窗
    confirm() {
      // 处理只有检验规则的数据
      this.modelList = this.modelList
        .filter((item) => item.rule)
        .map((item) => {
          return { ...item, rule: JSON.parse(item.rule) };
        });
      this.isModelProp = false;
    },
    // 点击查看展示数据
    goViewInfo(item) {
      this.isdialog = true;
      this.fieldConfigs =
        item && item.fieldConfigs ? JSON.parse(item.fieldConfigs) : [];
    },
    //动态绑定表达式
    handlerInputChange(item) {
      item.expression = `${item.field}${item.operators}${item.value}`;
    },
    //添加计算规则
    addRule() {
      const params = {
        field: "",
        value: "",
        operator: "",
        dataConfig: [],
        expression: "",
        reserveType: "", //  是否保留
      };
      this.aggregationConfigs.push(params);
    },
    //是否保留原字段
    handlerChange(item) {
      if (item.reserveType == 0) {
        item.dataConfig = this.form.id ? item.dataConfig : [];
      } else if (item.reserveType == 1) {
        item.dataConfig = [
          {
            field: "",
            remark: "",
            type: "",
            size: "",
            decimals: "",
          },
        ];
      }
    },
    // 获取存储对象下拉数据
    remoteStore(query) {
      if (query !== undefined) {
        query = query.replace(/\s*/g, "");
      }
      this.storeOpt = [];
      let params = {
        pageSize: 10,
        curPage: 1,
        name: query === "" ? undefined : query,
      };
      this.loading = true;
      this.$axios.get(`${getStoreList}`, { params }).then((res) => {
        if (res.code === 200) {
          this.storeOpt = res.data.records;
          this.loading = false;
        }
      });
    },
    //获取数据源下拉数据
    remoteMethod(query) {
      if (query !== undefined) {
        query = query.replace(/\s*/g, "");
      }
      this.options = [];
      let params = {
        pageSize: 10,
        curPage: 1,
        name: query === "" ? undefined : query,
        mode: this.form.mode,
      };
      this.loading = true;
      this.$axios.get(`${getSourceList}`, { params }).then((res) => {
        if (res.code === 200) {
          this.options = res.data.records;
          console.log("res.data,record----->", res.data.records);
          this.loading = false;
        }
      });
    },
    // 选择存储对象发送请求
    getStore(value) {
      if (value !== "" && value !== undefined) {
        this.$axios.get(`${getStoreDetail}?id=${value}`).then((res) => {
          if (res.code === 200) {
            this.form.datastoreId = res.data.id;
          }
        });
      }
    },
    // 选择数据源
    getChange(value) {
      // 数据过滤可选
      this.filtDis = false;
      //数据计算可选
      this.disableCalc = false;
      this.getFilterRule();
      this.getMOdelData();
      this.getInSpectionRuleList();
      // 选择之后弹窗显示
      this.isModelProp = true;
    },
    // 解决切屏回到当前页面下拉框选项会自动弹出问题
    visibleChange(flag) {
      if (flag) {
        this.$refs.modelSelect.focus();
      } else {
        this.$refs.modelSelect.blur();
      }
    },
    // 获取检验规则下拉数据
    getInSpectionRuleList() {
      this.$axios
        .get(`${getInSpectionRuleOpt}`, { params: this.pages })
        .then((res) => {
          if (res.code === 200) {
            const { records } = res.data;
            this.inspectionRuleOpt = records.map((item) => {
              return {
                label: item.title,
                value: JSON.stringify({
                  type: 31,
                  code: item.code,
                  title: item.title,
                  content: item.content,
                }),
              };
            });
          }
        });
    },
    //获取属性列表
    getMOdelData() {
      if (
        this.form.datasourceId !== "" &&
        this.form.datasourceId !== undefined
      ) {
        this.$axios
          .get(`${getModelDetailUrl}?datasourceId=${this.form.datasourceId}`)
          .then((res) => {
            if (res.code === 200) {
              this.modelList = res.data;
              this.$refs.modelTable.doLayout();
            }
          });
      }
    },

    //获取数据源详情
    getFilterRule(value) {
      if (
        this.form.datasourceId !== "" &&
        this.form.datasourceId !== undefined
      ) {
        this.$axios
          .get(`${sourceDetailUrl}?id=${this.form.datasourceId}`)
          .then((res) => {
            if (res.code === 200) {
              console.log("res=====>", res);
              this.getFieldList(JSON.parse(res.data.config));
              const extra = JSON.parse(res.data.extra);
              console.log("extra=====>", extra);
              this.filtTale = extra.fieldLists;
              this.deleteList = extra.fieldLists;
            }
          });
      }
    },
    //获取字段下拉数据
    getFieldList(value) {
      this.$axios
        .post(`${watchInfo}`, value, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.fieldList = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //选择清空数据源
    clearChance() {
      this.filtDis = true;
      this.disableCalc = true;
      this.form.dataFilt = false;
      this.form.dataComputed = false;
    },

    // 编辑
    goNext(item) {
      console.log(647, item);
      this.form.id = item.id;
      this.form.name = item.name;
      this.title = "编辑";
      this.sourceDis = true;
      this.storeDis = true;
      const cron = JSON.parse(item.cron);
      console.log(cron);
      this.form.type = cron.type;
      this.form.cron = cron.cron;
      if (cron.type == 0) {
        this.timeOptions[0].checked = true;
      } else {
        this.cronOptions[0].checked = true;
      }
      this.form.datasourceId = item.datasourceId;
      this.form.datastoreId = item.datastoreId;
      if (item.operators && this.form.id) {
        const res = JSON.parse(item.operators);
        this.filtDis = false;
        this.isFiled = res;

        if (res.aggregation) {
          this.form.dataComputed = true;
          this.formCalcData.name = res.aggregation.name;
          this.aggregationConfigs = res.aggregation.aggregationConfigs.map(
            (item) => {
              return {
                ...item,
                dataConfig: this.formatDataConfig(item.dataConfig),
              };
            }
          );
        } else {
          this.form.dataComputed = false;
          this.aggregationConfigs = [
            {
              field: "",
              value: "",
              operator: "",
              dataConfig: [],
              expression: "",
              reserveType: "", //  是否保留
            },
          ];
        }
        if (res.filter) {
          this.form.dataFilt = true;
          this.filtTale = res.filter.filterConfigs;
          this.filtForm.delFields = res.filter.delFields;
          this.filtForm.name = res.filter.name;
        } else {
          this.form.dataFilt = false;
        }
      }
      this.outerVisible = true;
    },
    //处理数据
    formatDataConfig(args) {
      let data = {};
      let res = [];
      for (const key in args) {
        data[key] = args[key];
      }
      res.push(data);
      return res;
    },
    // 新建数据模型
    add() {
      this.title = "新建数据模型";
      this.sourceDis = false;
      this.storeDis = false;
      this.filtDis = true;
      this.disableCalc = true;
      this.outerVisible = true;
      this.aggregationConfigs = [
        {
          field: "",
          value: "",
          operator: "",
          dataConfig: [],
          expression: "",
          reserveType: "", //  是否保留
        },
      ];
    },
    checkTime(item) {
      this.cronOptions[0].checked = !item.checked;
      this.form.type = item.value;
      if (item.checked) {
        this.form.mode = 0;
        this.remoteMethod();
      }
    },
    checkCron(item) {
      this.timeOptions[0].checked = !item.checked;
      this.form.type = item.value;
      if (item.checked) {
        this.form.mode = 1;
        this.remoteMethod();
      }
    },
    //数据过滤
    goFiltration(value) {
      this.dialogFit = true;
      if (this.title === "新建数据模型") {
        // this.getChange(this.form.datasourceId);
        // this.getStore(this.form.datastoreId);
      }
      this.getCode();
    },
    //获取加减乘除计算符号
    getSymbol() {
      this.symbolCode = [];
      this.$axios.get(`${getSymbol}`).then((res) => {
        if (res.code === 200) {
          res.data[0].children.forEach((item) => {
            let data = {};
            data.label = item.dictValue;
            data.value = item.dictKey;
            this.symbolCode.push(data);
          });
        }
      });
    },
    //获取计算符号
    getCode() {
      if (this.filtCode.length == 0) {
        this.$axios.get(`${getCode}`).then((res) => {
          if (res.code === 200) {
            console.log(res.data[0].children);
            res.data[0].children.forEach((item) => {
              let data = {};
              data.label = item.dictValue;
              data.value = item.dictKey;
              this.filtCode.push(data);
            });
          }
        });
      }
    },
    //数据计算
    goComputed() {
      this.dialogComputed = true;
      this.getSymbol();
    },
    //数据计算提交
    submitFit() {
      const result = this.$refs.filtForm.validate();
      if (result === true) {
        this.dialogFit = false;
        this.form.dataFilt = true;
        this.isCheckFilter = true;
      }
    },
    // 关闭数据过滤弹窗
    closeFit() {
      this.isCheckFilter = false;
      this.handlerFilterCheck();

      this.dialogFit = false;
    },
    closeFilter() {
      this.handlerFilterCheck();
    },
    //关闭数据计算弹窗
    closeCalcDialog() {
      this.handlerCalcCheck();
    },
    cancelbtn() {
      this.isCheckAggregation = false;
      this.handlerCalcCheck();
      this.dialogComputed = false;
    },
    // 数据计算提交完成
    submitCalc() {
      const result = this.$refs.formCalcData.validate();
      if (result === true) {
        this.dialogComputed = false;
        this.form.dataComputed = true;
        this.isCheckAggregation = true;
      }
    },
    handlerFilterCheck() {
      if (this.form.id) {
        if (this.isFiled.filter) {
          this.form.dataFilt = true;
        } else {
          this.isCheckFilter
            ? (this.form.dataFilt = true)
            : ((this.form.dataFilt = false), this.filtInit());
        }
      } else {
        if (this.isCheckFilter) {
          this.form.dataFilt = true;
        } else {
          this.form.dataFilt = false;
          this.filtInit();
        }
      }
    },
    handlerCalcCheck() {
      if (this.form.id) {
        if (this.isFiled.aggregation) {
          this.form.dataComputed = true;
        } else {
          this.isCheckAggregation
            ? (this.form.dataComputed = true)
            : ((this.form.dataComputed = false), this.initCalcData());
        }
      } else {
        if (this.isCheckAggregation) {
          this.form.dataComputed = true;
        } else {
          this.form.dataComputed = false;
          this.aggregationConfigs = [
            {
              field: "",
              value: "",
              operator: "",
              dataConfig: [],
              expression: "",
              reserveType: "", //  是否保留
            },
          ];
        }
      }
    },
    //提交
    submit() {
      let cron = {
        type: this.form.type,
        cron: this.form.cron,
      };
      const res = this.aggregationConfigs.map((item) => {
        return { ...item, dataConfig: { ...item.dataConfig[0] } };
      });
      this.filtTale = this.filtTale
        .map((item, index) => {
          delete item.size;
          delete item.type;
          delete item.remark;
          return { ...item };
        })
        .filter((ite) => ite.expression);
      let operators;
      if (this.form.dataFilt) {
        operators = {
          filter: {
            name: this.filtForm.name,
            delFields: this.filtForm.delFields,
            filterConfigs: this.filtTale,
          },
        };
      } else if (this.form.dataComputed) {
        operators = {
          aggregation: {
            name: this.formCalcData.name,
            aggregationConfigs: res,
          },
        };
      }
      if (this.form.dataFilt && this.form.dataComputed) {
        operators = {
          filter: {
            name: this.filtForm.name,
            delFields: this.filtForm.delFields,
            filterConfigs: this.filtTale,
          },
          aggregation: {
            name: this.formCalcData.name,
            aggregationConfigs: res,
          },
        };
      }
      const params = {
        id: this.form.id, //有id为编辑，无为新增
        name: this.form.name, //模型名称
        datasourceId: this.form.datasourceId, //数据源
        cron: JSON.stringify(cron), //模型数据更新周期
        operators: JSON.stringify(operators), // 数据过滤或数据计算
        datastoreId: this.form.datastoreId, //存储对象
        fieldConfigs: JSON.stringify(this.modelList),
        mode: this.form.mode,
      };
      console.log(params);
      const result = this.$refs.formPanel.validate();
      if (result === true) {
        this.$axios
          .post(`${addModel}`, params, {
            headers: { "Content-Type": "application/json" },
          })
          .then((res) => {
            if (res.code === 200) {
              this.params.curPage = 1;
              this.$refs.search.getList(this.params);
              this.$message.success("保存成功");
              this.outerVisible = false;
              this.filtInit();
              this.dataInit();
              this.initCalcData();
            } else {
              this.$message.success("保存失败");
            }
          });
      }
    },
    initCalcData() {
      this.formCalcData.name = "";
    },
    filtInit() {
      this.filtForm = {
        delFields: [],
        name: "",
      };
      this.filtTale = [
        {
          field: "",
          value: "",
          operator: "",
          expression: "",
        },
      ];
    },
    dataInit() {
      this.filtInit();
      this.initCalcData();
      this.form = {
        id: "", // 数据唯一值
        name: "",
        type: "",
        datasourceId: "",
        datastoreId: "",
        cron: "",
        dataFilt: false, //是否数据过滤
        dataComputed: false, // 是否禁用
      };
      this.timeOptions = [
        {
          value: "0",
          label: "实时",
          checked: false,
        },
      ];
      this.cronOptions = [
        {
          value: "1",
          label: "固定周期（cron表达式）",
          checked: false,
        },
      ];
      this.fitOptions = [
        {
          value: "数据过滤",
          label: "数据过滤",
          checked: false,
        },
      ];
      this.comOptions = [
        {
          value: "数据计算",
          label: "数据计算",
          checked: false,
        },
      ];
    },
    //删除模型管理资源
    delectSource(id) {
      this.$axios
        .post(`${removeMode}?ids=${id}`, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.code == 200) {
            this.params.curPage = 1;
            this.$refs.search.getList(this.params);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.model-prop {
  .item-c {
    .item-table {
      ::v-deep .el-table {
        display: flex;
        flex-direction: column;
        max-height: 700px;
        .el-table__header-wrapper {
          overflow: visible !important;
        }
        .el-table__body-wrapper {
          height: 100% !important;
        }
      }
    }
  }
}

.dialog-footer {
  display: flex;
  justify-content: space-between;
}

.viewDialog {
  ::v-deep .dialog-footer {
    justify-content: flex-end;
  }
}
.sourceForm {
  box-sizing: border-box;
  height: 100%;
  .bottom-wrap {
    padding: 50px 20px;
    height: calc(100% - 150px);
    .title {
      font-size: 16px;
      font-weight: bold;
    }
    .mt50 {
      margin-top: 30px;
    }
    .item-form {
      margin-left: 50px;
    }
    .item-model {
      display: flex;
    }
  }
}
// 滚动条的宽度
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
  width: 6px; // 横向滚动条
  height: 6px; // 纵向滚动条 必写
}
// 滚动条的滑块
/deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 3px;
}
</style>
